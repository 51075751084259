html {
  font-size: 10px !important; }
  html #__next {
    height: 100%; }
  html p,
  html span {
    font-size: 1em; }
  html h1 {
    font-size: 2em; }
  html h2 {
    font-size: 1.5em; }
  html h3 {
    font-size: 1.17em; }
  html h4 {
    font-size: 1em; }
  html h5 {
    font-size: 0.83em; }
  html h6 {
    font-size: 0.67em; }
